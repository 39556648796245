












































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import TextEditor from "@/components/shared/TextEditor.vue";

import { SiteOptions } from "@/types/interfaces";

interface HeaderObject {
    id: string;
    text: string;
    children?: Array<HeaderObject>;
}

function getPreviousElementBySelector(elem: HTMLElement, selector: string) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
}

export default Vue.extend({
    components: {
        PageTitleHeader,
        TextEditor,
        Spinners
    },
    data() {
        return {
            userPageContent: "",
            headers: [] as Array<HeaderObject>,
            parsedContent: "",
            showNotification: false,
            isLoading: true,
            editorSettings: [
                [{ header: [2, 3, false] }],
                ["bold", "italic"],
                ["link"],
                [
                    { list: "bullet" },
                    { list: "ordered" },
                    { indent: "-1" },
                    { indent: "+1" }
                ]
            ],
            payload: {} as SiteOptions
        };
    },
    computed: {
        pageContent(): string {
            return this.options[this.contentOption]
                ? (this.options[this.contentOption] as any)
                : "";
        },
        pageConfigOption(): Record<string, string> {
            const returnValue = this.$store.getters.getPageOptions(
                this.$route?.name?.toLocaleLowerCase()
            );
            return returnValue;
        },

        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },

        options(): SiteOptions {
            return this.optionsStore.options;
        },

        isSuperUser(): boolean {
            return this.$store.getters.isSuperUser;
        },

        hasError(): boolean {
            return false;
            // return pagesStore.hasError;
        },

        notificationContent(): string {
            return this.hasError
                ? "There was an error saving the content."
                : "The content has been saved.";
        },

        notificationClasses(): string {
            return this.hasError ? "my-2 is-danger" : "my-2 is-success";
        },

        contentOption(): keyof SiteOptions {
            return this.$route?.meta?.contentOption
                ? this.$route.meta.contentOption
                : "helpContent";
        },
        textHeader(): string {
            return this.pageConfigOption ? (this.pageConfigOption.textHeader ? this.pageConfigOption.textHeader : this.$route?.meta?.title)  
            : this.$route?.meta?.title
        }
    },

    watch: {
        userPageContent() {
            // TODO: debounce this somehow, maybe? it should only be potentially slow for editors; for regular users this should only happen once
            this.headers = [];

            const container = document.createElement("div");
            container.innerHTML = this.userPageContent;

            const allHeaders = container.querySelectorAll("h2");

            if (allHeaders.length) {
                allHeaders.forEach((header, index) => {
                    const headerId = `header-${index}`;
                    header.id = headerId;
                    this.headers.push({
                        text: header.innerText,
                        id: headerId,
                        children: []
                    });
                });

                const subheaders = container.querySelectorAll("h3");

                subheaders.forEach((subheader, index) => {
                    const header = getPreviousElementBySelector(
                        subheader,
                        "h2"
                    );

                    if (header) {
                        const headerObj = this.headers.find(
                            (item) => item.id === header.id
                        );

                        if (headerObj && headerObj.children) {
                            const subheaderId = `${headerObj.id}-sub-${index}`;
                            subheader.id = subheaderId;
                            headerObj.children.push({
                                text: subheader.innerText,
                                id: subheaderId
                            });
                        }
                    }
                });
            }

            this.parsedContent = container.innerHTML;
        }
    },

    mounted() {
        this.setupContent();
    },

    methods: {
        async setupContent(): Promise<void> {
            await this.$store.dispatch("getOptions", [this.contentOption]);
            this.userPageContent = this.pageContent;
            this.isLoading = false;
        },

        async saveContent(): Promise<void> {
            this.payload[this.contentOption] = this.userPageContent as any;
            try {
                await this.$store.dispatch("updateOptions", this.payload);
            } catch (error) {
                console.log(error);
            }

            this.showNotification = true;
        },

        disableNotification(): void {
            this.showNotification = false;
        },


    }
});
