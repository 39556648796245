<template>
    <div>
        <label class="italic" v-if="helpTextLabel" for="">{{
            helpTextLabel
        }}</label>
        <editor v-model="content" :api-key="apiKey" :init="settings" />
    </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { mapGetters } from "vuex";

export default {
    name: "App",
    components: {
        editor: Editor
    },
    props: {
        editContent: {
            type: String,
            default: ""
        },
        helpTextLabel: {
            type: String,
            default: ""
        },
        activeToolbar: {
            type: String,
            default:
                "undo redo | formatselect | bold italic | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent"
        }
    },
    data() {
        return {
            apiKey: "iwjbas3qyblp09oxjldvn9zy4roign4lylc94grrigpu36vt",
            init: {
                height: 350,
                branding: false,
                plugins: ["lists link paste wordcount code"],
                toolbar: this.activeToolbar,
                menubar: false
            },
            content: this.editContent
        };
    },
    watch: {
        editContent(newValue) {
            this.content = newValue;
        },
        content(newValue) {
            this.$emit("input", newValue);
        }
    },
    computed: {
        ...mapGetters(["userInfo"]),
        toolbar() {
            return this.userInfo.superuser
                ? this.activeToolbar + " | code"
                : this.activeToolbar;
        },
        settings() {
            return {
                height: 350,
                branding: false,
                plugins: ["lists link paste wordcount code"],
                toolbar: this.toolbar,
                menubar: false
            };
        }
    }
};
</script>
